import { Link } from "react-router-dom";
import Logo1 from "../images/Logo2.png";

const Logo = () => {
  return (
    <Link to="/">
      <img src={Logo1} className="h-8 pl-3" alt=""/>
    </Link>
  );
};

export default Logo;
