import {
  DocumentReportIcon,
  UserGroupIcon,
  CollectionIcon,
  UserIcon,
} from "@heroicons/react/outline";
import Header from "../components/Header";
import Button from "../components/Button";
import Nisarg from "../images/nisarg.png";
import Abhi from "../images/profile.jpg";
import Jay from "../images/jay.jpeg";
import { ReactComponent as PieChart } from "../images/pie-chart.svg";
import { ReactComponent as CircleGroup } from "../images/circle-group.svg";
// import { ReactComponent as Linode } from "../images/Linode.svg";
import DashboardImg from "../images/dashboard.png";
import GroupImg from "../images/group.png";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const features = [
  {
    name: "Group Expenses",
    description: "Share your expenses among group of friends with ease.",
    icon: UserGroupIcon,
  },
  {
    name: "Manage Expenses",
    description:
      "Keep track of your expenses. Manage whom you owe and also who owes you.",
    icon: CollectionIcon,
  },
  {
    name: "Share it Individually",
    description: "Share the bill with your friends individually.",
    icon: UserIcon,
  },

  {
    name: "Expense Reporting",
    description: "Track all your expenses with our reporting service.",
    icon: DocumentReportIcon,
  },
];

export default function LandingPage() {
  useEffect(() => {
    document.title = "SquareSplit | Home";
  }, []);

  return (
    <div className="bg-white">
      <Header />
      <main>
        {/* Hero section */}
        <div className="relative">
          <div className="absolute inset-x-0 bottom-0" />
          <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="relative sm:overflow-hidden">
              <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                <h1 className="text-4xl font-extrabold tracking-tight text-center sm:text-5xl lg:text-6xl">
                  <span className="block text-gray-800">
                    Split bills with your friends
                  </span>
                  <span className="block text-blue-600">
                    without any hassle
                  </span>
                </h1>
                <p className="max-w-lg mx-auto mt-6 text-xl text-center text-gray-500 sm:max-w-3xl">
                  Split bills with your friends easily and efficiently. Register
                  today with your friends
                </p>
                <div className="max-w-sm mx-auto mt-10 sm:max-w-10 sm:flex sm:justify-center">
                  <Link to="/signup" className="w-full">
                    <Button width="w-full">Get Started</Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Logo Cloud */}
        <div>
          <div className="px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <p className="text-sm font-semibold tracking-wide text-center text-gray-500 uppercase">
            </p>
            <div className="mt-6">
              <div className="flex justify-center">
                <a
                  href=""
                  target="_blank"
                  rel="noreferrer"
                >
                  
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Alternating Feature Sections */}
        <div className="relative pt-16 pb-32 overflow-hidden">
          <div aria-hidden="true" className="absolute inset-x-0 top-0 h-48 " />
          <div className="relative">
            <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
              <div className="max-w-xl px-4 mx-auto sm:px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0">
                <div>
                  <div>
                    <PieChart className="w-12 h-12" />
                  </div>
                  <div className="mt-6">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                      Stay on top of your bills
                    </h2>
                    <p className="mt-4 text-lg text-gray-500">
                      No need to remember the expenses. Use{" "}
                      <span className="font-bold">Split Bill</span> to keep
                      track of your bills and share your expenses with your
                      friends.
                    </p>
                    <div className="mt-6">
                      <Link to="/signup">
                        <Button margin="m-0">Get Started</Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0">
                <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0">
                  <img
                    className="w-full shadow-xl rounded-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                    src={DashboardImg}
                    alt="dashboard"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-24">
            <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
              <div className="max-w-xl px-4 mx-auto sm:px-6 lg:col-start-2 lg:mx-0 lg:max-w-none lg:py-32 lg:px-0">
                <div>
                  <div>
                    <CircleGroup />
                  </div>
                  <div className="mt-6">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                      Create Groups for easy management
                    </h2>
                    <p className="mt-4 text-lg text-gray-500">
                      Want to share your expenses with group of friends? No
                      worries create groups and share it easily.
                    </p>
                    <div className="mt-6">
                      <Button>Get Started</Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-12 sm:mt-16 lg:col-start-1 lg:mt-0">
                <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:relative lg:m-0 lg:h-full lg:px-0">
                  <img
                    className="w-full shadow-xl rounded-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                    src={GroupImg}
                    alt="dashboard"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Gradient Feature Section */}
        <div className="bg-gradient-to-r from-blue-800 to-indigo-700">
          <div className="max-w-4xl px-4 py-16 mx-auto sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:px-8 lg:pt-24">
            <h2 className="text-3xl font-extrabold tracking-tight text-white">
              Our Features
            </h2>
            <p className="max-w-3xl mt-4 text-lg text-gray-200">
              SplitBill has lots of features. Explore the features below to know
              what you are missing out on.
            </p>
            <div className="grid grid-cols-1 mt-12 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
              {features.map((feature) => (
                <div key={feature.name}>
                  <div>
                    <span className="flex items-center justify-center w-12 h-12 bg-white rounded-md bg-opacity-10">
                      <feature.icon
                        className="w-6 h-6 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <div className="mt-6">
                    <h3 className="text-lg font-medium text-white">
                      {feature.name}
                    </h3>
                    <p className="mt-2 text-base text-blue-200">
                      {feature.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>

      <footer className="bg-gray-50" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="px-4 pt-16 pb-8 mx-auto max-w-7xl sm:px-6 lg:px-8 lg:pt-24">
          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-sm font-semibold tracking-wider text-gray-600 uppercase">
                Developed by
              </h3>
              <div className="flex items-center justify-between gap-4 mt-6 w-fit">
              <div>
                  <a
                    href="https://bento.me/nisargpatel"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Nisarg} alt="Nisarg"  className="w-20 h-20 rounded"></img>
                    <p className="pb-2 mb-4 text-sm font-semibold text-center text-gray-500 border-b">
                      Nisarg Patel
                    </p>
                  </a>
                </div>
                <div>
                  
                    <img src={Abhi} alt="Abhi" className="w-20 h-20 rounded-full"></img>
                    <p className="pb-2 mb-4 text-sm font-semibold text-center text-gray-500 border-b">
                    Abhi Rupareliya
                    </p>
                 
                </div>
                <div>
                  <a
                    href="https://bento.me/jaythanki"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Jay} alt="Jay"  className="w-20 h-20 rounded-full"></img>
                    <p className="pb-2 mb-4 text-sm font-semibold text-center text-gray-500 border-b">
                      Jay Thanki
                    </p>
                  </a>
                </div>
                
                <a
                  href=""
                  target="_blank"
                  rel="noopener noreferrer"
                >
                 
                </a>
              </div>
            </div>
            <div>
              <a
                href=""
                target="_blank"
                rel="noreferrer"
                className="flex justify-end w-full"
              >
               
                
              </a>
            </div>
          </div>
          <div className="pt-8 mt-12 border-t border-gray-200 md:flex md:items-center md:justify-between lg:mt-16">
            <p className="mt-8 text-base text-gray-400 md:order-1 md:mt-0">
              
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}
