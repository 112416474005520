import { ChevronRightIcon, CurrencyRupeeIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { authService } from "services";
import ExpenseDetailModal from "./ExpenseDetailModal";

type ExpenseProps = {
  expenseList: any[];
  settled?: boolean;
};

const ExpenseList = ({ expenseList, settled = false }: ExpenseProps) => {
  const [showExpenseDetail, setShowExpenseDetail] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState<any>();
  const currentUser: any = authService.getCurrentUser();

  function calculateLentAmount(expense : any){
    let lentAmount = 0;
    for(let memberBalance of expense.membersBalance){
      if((memberBalance.memberId.toString() !== currentUser._id) && memberBalance.status === "pending"){
        lentAmount += memberBalance.amount;
      }
    }
    return lentAmount.toFixed(2);
  }
  return (
    <>
      {expenseList && expenseList.length > 0 ? (
        <div className="mt-2 overflow-y-auto border divide-y rounded shadow">
          {expenseList.map((expense: any) => (
            <div
              key={expense._id}
              className="grid items-center grid-cols-3 px-3 py-1  hover:cursor-pointer hover:bg-gray-50 sm:grid-cols-5"
              onClick={() => {
                setShowExpenseDetail(true);
                setSelectedExpense(expense);
              }}
            >
              <div className="pl-2">
                <p className="mt-1 text-lg font-semibold text-blue-600 ">
                  {expense.description}
                </p>
                <p className="font-medium text-gray-500 text-md">
                ₹ {Number(expense.amount).toFixed(2)}
                </p>
              </div>
              <div className="hidden col-span-2 text-sm text-gray-500 justify-self-center sm:block">
                <p>
                  Paid by{" "}
                  <span className="font-medium text-gray-600">
                    {expense.paidBy._id === currentUser._id
                      ? "You"
                      : expense.paidBy.name}
                  </span>
                </p>
                <p>
                  on{" "}
                  <span className="font-medium text-gray-600">
                    {new Date(expense.date).toUTCString().slice(0, 17)}
                  </span>
                </p>
              </div>
              {expense.paidBy._id === currentUser.id ? (
                <div className="text-sm font-semibold text-green-600 justify-self-center">
                  <p>You Lent</p>
                  <p>
                  ₹{" "}
                    {
                      // sum of all the amounts lent by the current user
                      calculateLentAmount(expense)
                    }
                  </p>
                </div>
              ) : (
                <div
                  className={`${
                    settled ? "text-green-600" : "text-red-500"
                  } justify-self-center text-sm font-semibold`}
                >
                  {settled ? <p>You Paid</p> : <p>You Owe</p>}
                  <p>
                  ₹{" "}
                    {
                      expense?.membersBalance
                        ?.find(
                          (member: any) =>
                            member?.memberId?.toString() === currentUser.id
                        )
                        .amount
                    }
                  </p>
                </div>
              )}
              <div className="text-gray-400 justify-self-end">
                <ChevronRightIcon className="w-5" />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="p-2 text-center border-2 border-dashed rounded">
          <div className="flex justify-center">
            <CurrencyRupeeIcon className="w-10 stroke-1 stroke-slate-600" />
          </div>
          <h3 className="mt-2 font-medium text-gray-900 text">
            {settled ? "Nothing to Show" : "No Active Expenses"}
          </h3>
          <p className="mt-1 text-gray-500 text">
            Add expenses by clicking the + button.
          </p>
        </div>
      )}
      <ExpenseDetailModal
        expense={selectedExpense}
        settled={settled}
        open={showExpenseDetail}
        setOpen={setShowExpenseDetail}
      />
    </>
  );
};

export default ExpenseList;
