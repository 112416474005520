import { ExternalLinkIcon,PlusCircleIcon, UserGroupIcon } from "@heroicons/react/outline";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { Loading } from "components";
import Button from "components/Button";
import { ReactComponent as Group } from "../../images/group.svg";
import { ReactComponent as MoneyBag } from "../../images/MoneyBag.svg";
import GroupContext from "contexts/GroupContext";
import { useContext } from "react";
import { Link } from "react-router-dom";

const Groups = () => {
  const { groupList } = useContext(GroupContext);

  return (
    <div>
      {/* Page Heading */}
      <div className="mt-4 flex flex-1 flex-col justify-end px-4 sm:px-6 lg:mx-auto lg:px-8 xl:max-w-6xl">
        <div>
          <nav className="sm:hidden" aria-label="Back">
            <Link
              to="/"
              className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
            >
              <ChevronLeftIcon
                className="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              Back
            </Link>
          </nav>
          <nav className="hidden sm:flex" aria-label="Breadcrumb">
            <ol className="flex items-center space-x-4">
              <li>
                <div className="flex">
                  <Link
                    to="/"
                    className="text-sm font-medium text-gray-500 hover:text-gray-700"
                  >
                    Home
                  </Link>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <ChevronRightIcon
                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <p className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                    Groups
                  </p>
                </div>
              </li>
            </ol>
          </nav>
        </div>
        <div className="mt-2 flex items-center justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
              Your Groups
            </h2>
          </div>
          <div className="mt-4 hidden flex-shrink-0 md:mt-0 md:ml-4 md:flex">
            <Link to="/addgroup">
              <Button leftIcon={<PlusCircleIcon className="w-5" />}>
                Add Group
              </Button>
            </Link>
          </div>
          <div className="flex flex-shrink-0 md:mt-0 md:ml-4 md:hidden">
            <Link to="/addgroup">
              <Button>
                <PlusCircleIcon className="w-5" />
              </Button>
            </Link>
          </div>
        </div>
        {groupList ? (
          <div className=" grid w-full space-y-3 sm:place-content-center sm:place-items-center sm:space-y-0 md:grid-cols-2 lg:grid-cols-3">
            {groupList.map((group) => (
              <div
                key={group._id}
                className="mb-6 mt-6 flex h-56 w-3/4 min-w-full flex-col justify-between rounded border-2 shadow-sm sm:min-w-0"
              >
                <div className="p-2 py-3 px-6">
                  <div className="mb-3 flex flex-col  justify-between border-b pb-2">
                    <p className=" truncate text-2xl font-bold text-gray-800 ">
                      {group.name}
                    </p>
                    <p className="mt-2 truncate text-sm text-gray-500">
                      {group.description}
                    </p>
                  </div>
                  <div>
                    <p className="flex items-center text-sm font-semibold uppercase text-gray-500">
                      <span className="mr-2">
                        <MoneyBag className="h-6 w-6" />
                      </span>
                      Total Expenses :
                      <span className="ml-1 text-2xl font-semibold text-gray-800">
                        {group.totalExpenses}
                      </span>
                    </p>
                  </div>
                  <div className="mt-3 flex flex-col items-start">
                    <p className="flex items-center text-sm font-semibold uppercase text-gray-500">
                      <span className="mr-2">
                        <Group className="h-6 w-6" />
                      </span>
                      Members :
                      <span className="ml-1 text-2xl font-semibold text-gray-800">
                        {group.members.length}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="flex justify-end bg-gray-100 p-2">
                  <Link to={`/group/detail/${group._id}`}>
                    <Button
                      type="link"
                      rightIcon={<ExternalLinkIcon className="w-5" />}
                    >
                      Open
                    </Button>
                  </Link>
                </div>
              </div>
            ))}
            {groupList.length < 3 && (
              <>
                <Link to="/addgroup" className="h-56 shadow-sm sm:w-3/4">
                  <div className="flex h-56 min-w-full cursor-pointer flex-col items-center  justify-center rounded border-2 border-dashed hover:bg-gray-50 sm:min-w-0">
                    <p>
                      <PlusCircleIcon className="mb-4 w-10 stroke-1 text-gray-600" />{" "}
                    </p>
                    <p className="text-2xl font-medium text-gray-600">
                      Add Group
                    </p>
                  </div>
                </Link>
              </>
            )}
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default Groups;