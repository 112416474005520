import { CgSpinner } from "react-icons/cg";
import LoadingGIF from "../images/loading1.gif";

function ReloadHomePage() {
  window.location.reload();
}

const Loading = ({
  isServerLoading = false,
  retryCount = 0,
  maxRetries = 4,
}: {
  isServerLoading?: boolean;
  retryCount?: number;
  maxRetries?: number;
}) => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      {isServerLoading ? (
        <>
          <img src={LoadingGIF} alt="loading" className="h-64" />
          <p className="mt-8 text-xl font-semibold text-center align-middle animate-pulse">
            Connecting to Server... ({retryCount}/{maxRetries})
          </p>
          {retryCount >= maxRetries && (
            <button onClick={ReloadHomePage} className="px-4 py-2 mt-4 text-white bg-blue-500 rounded">
              Reload
            </button>
          )}
        </>
      ) : (
        <div className="flex items-center justify-center w-full h-full">
          <CgSpinner className="text-3xl animate-spin" />
        </div>
      )}
    </div>
  );
};

export default Loading;
