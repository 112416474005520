import { Dialog, Transition } from "@headlessui/react";
import { XIcon, ReplyIcon, CheckCircleIcon, XCircleIcon } from "@heroicons/react/outline";
import Button from "components/Button";
import GroupContext from "contexts/GroupContext";
import ToastContext from "contexts/ToastContext";
import { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authService, expenseService, userService } from "services";

type ModalProps = {
  children?: any;
  open: boolean;
  setOpen: (open: boolean) => void;
  expense: any;
  settled?: boolean;
};

type User = {
  _id: string;
  name: string;
};

const ExpenseDetailModal = ({
  children,
  open = false,
  setOpen,
  expense,
  settled = false,
}: ModalProps) => {
  const currentUser: any = authService.getCurrentUser();
  const navigate = useNavigate();
  const { showToast } = useContext(ToastContext);
  const { fetchExpenses } = useContext(GroupContext);

  const handleSettleExpense = async () => {
    const response = await expenseService.settleExpense(expense._id, currentUser.id);
    if (response) {
      showToast("Expense settled", "success");
      setOpen(false);
      await fetchExpenses(expense.group);
    }
  };

  const handleRevertExpense = async () => {
    const response = await expenseService.revertExpense(expense._id, currentUser.id);
    if (response) {
      showToast("Expense reverted", "success");
      setOpen(false);
      await fetchExpenses(expense.group);
    }
  };

  const [settledMembers, setSettledMembers] = useState<(User & { amount: number })[]>([]);
  const [pendingMembers, setPendingMembers] = useState<(User & { amount: number })[]>([]);

  useEffect(() => {
    async function fetchUsers() {
      const settledUsers: User[] = await userService.fetchUsersByIds(expense.settledMembers);
      const filteredSettledUsers = settledUsers
        .filter((user: User) => user._id !== expense.paidBy._id) // Exclude the payer
        .map(user => ({
          ...user,
          amount: expense.membersBalance.find((member: any) => member.memberId === user._id)?.amount || 0,
        }));
      setSettledMembers(filteredSettledUsers);

      const allMemberIds: string[] = expense.membersBalance.map((member: any) => member.memberId);
      const pendingMemberIds: string[] = allMemberIds.filter(
        (id: string) => !expense.settledMembers.includes(id) && id !== expense.paidBy._id
      ); // Exclude the payer
      const pendingUsers: User[] = await userService.fetchUsersByIds(pendingMemberIds);
      const filteredPendingUsers = pendingUsers.map(user => ({
        ...user,
        amount: expense.membersBalance.find((member: any) => member.memberId === user._id)?.amount || 0,
      }));
      setPendingMembers(filteredPendingUsers);
    }

    if (expense) {
      fetchUsers();
    }
  }, [expense]);



  return (
    <>
      {expense ? (
        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpen}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="px-4 pt-2 pb-4 bg-white w-80 sm:w-auto sm:px-6 sm:pt-3 sm:pb-4">
                      <div className="flex justify-end w-full">
                        <Button type="icon" onClick={() => setOpen(false)}>
                          <XIcon className="w-5 text-gray-600" />
                        </Button>
                      </div>
                      <div className="flex items-center justify-between mt-4">
                        <div>
                          <p className="text-2xl font-semibold leading-5 text-gray-700">
                            {expense.description}
                          </p>
                          <p className="mt-2 leading-5 text-gray-500">
                            Paid by{" "}
                            <span className="font-medium text-gray-600">
                              {expense.paidBy.name}
                            </span>
                          </p>
                        </div>
                        <div>
                          <p className="mt-2 text-xl font-semibold leading-5 text-gray-700 sm:text-3xl">
                            ₹ {Number(expense.amount).toFixed(2)}
                          </p>
                        </div>
                      </div>
                      <div className="mt-3">
                        {expense.paidBy._id === currentUser.id ? (
                          <div className="text-lg font-semibold text-green-600 justify-self-center">
                            <p>
                              You Lent{" "}
                              <span>
                                ₹{" "}
                                {expense?.membersBalance.reduce((totalLentAmount: number, member: any) => {
                                  if (member?.memberId?.toString() !== currentUser.id && member.status === "pending") {
                                    return totalLentAmount + member.amount;
                                  } else {
                                    return totalLentAmount;
                                  }
                                }, 0).toFixed(2)}
                              </span>

                            </p>
                          </div>
                        ) : (
                          <div
                            className={`${settled ? "text-green-600" : "text-red-500"
                              } justify-self-center text-lg font-semibold`}
                          >
                            <p>
                              {settled ? "Settled" : "You Owe"}{" "}
                              <span>
                                ₹{" "}
                                {
                                  expense?.membersBalance
                                    ?.find(
                                      (member: any) =>
                                        member?.memberId?.toString() ===
                                        currentUser.id
                                    )
                                    .amount
                                }
                              </span>
                            </p>
                          </div>
                        )}
                      </div>

                      <div className="mt-6">
                        {settledMembers.length > 0 ? (
                          <div>
                            <p className="pb-1 my-2 font-semibold text-gray-700 uppercase border-b">
                              Settled By{" "}
                            </p>
                            {settledMembers.map((member) => {
                              return (
                                <p className="flex items-center" key={member._id}>
                                  <span className="mr-1 rounded-full bg-emerald-500">
                                    <CheckCircleIcon className="w-5 text-white" />
                                  </span>
                                  <span>{member.name} - ₹ {member.amount.toFixed(2)}</span>
                                </p>
                              );
                            })}
                          </div>
                        ) : (
                          <>
                            <p className="text-sm font-semibold text-gray-700 uppercase">
                              No one settled yet
                            </p>
                          </>
                        )}
                      </div>

                      <div className="mt-6">
                        {pendingMembers.length > 0 ? (
                          <div>
                            <p className="pb-1 my-2 font-semibold text-gray-700 uppercase border-b">
                              Pending{" "}
                            </p>
                            {pendingMembers.map((member) => {
                              return (
                                <p className="flex items-center" key={member._id}>
                                  <span className="mr-1 rounded-full">
                                    <XCircleIcon className="w-5 text-red-500" />
                                  </span>
                                  <span>{member.name} - ₹ {member.amount.toFixed(2)}</span>
                                </p>
                              );
                            })}
                          </div>
                        ) : (
                          <>
                            <p className="text-sm font-semibold text-gray-700 uppercase">
                              No pending members
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                    {expense.paidBy._id !== currentUser.id && !settled && (
                      <div className="flex justify-end p-3 mt-3 bg-gray-100 border-t">
                        <Button type="success" onClick={handleSettleExpense}>
                          Settle Up
                        </Button>
                      </div>
                    )}
                    {settled && (
                      <div className="flex justify-end p-3 mt-3 bg-gray-100 border-t">
                        <Button
                          type="danger"
                          onClick={handleRevertExpense}
                          leftIcon={<ReplyIcon className="w-5 mr-1" />}
                        >
                          Revert
                        </Button>
                      </div>
                    )}
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      ) : null}
    </>
  );
};

export default ExpenseDetailModal;
