import React, { useMemo } from 'react';
import { ChevronDoubleRightIcon,CurrencyRupeeIcon } from "@heroicons/react/outline";
import { authService } from "services";

type PaymentDueProps = {
  expenseList: any[];
  memberList: any[];
};

type Balance = {
  memberId: string;
  balance: number;
};

type Transaction = {
  from: string;
  to: string;
  amount: number;
};

const PaymentDue = ({ expenseList, memberList }: PaymentDueProps) => {
  const currentUser: any = authService.getCurrentUser();

  const balances = useMemo(() => {
    const balanceMap: { [key: string]: number } = {};

    // Initialize balances for each member
    memberList.forEach((member: any) => {
      balanceMap[member._id] = 0;
    });

    // Calculate the net balance for each member
    expenseList.forEach((expense: any) => {
      expense.membersBalance.forEach((memberBalance: any) => {
        const memberId = memberBalance.memberId.toString();
        if (expense.paidBy._id === memberId) {
          // Creditor: the person who paid
          balanceMap[memberId] += (expense.amount - memberBalance.amount);
        } else {
          // Debtor: everyone else
          balanceMap[memberId] -= memberBalance.amount;
        }
      });
    });

    return balanceMap;
  }, [expenseList, memberList]);

  const settlements = useMemo(() => {
    const creditors: Balance[] = [];
    const debtors: Balance[] = [];

    // Separate creditors and debtors
    Object.keys(balances).forEach(memberId => {
      const balance = balances[memberId];
      if (balance > 0) {
        creditors.push({ memberId, balance });
      } else if (balance < 0) {
        debtors.push({ memberId, balance });
      }
    });

    const transactions: Transaction[] = [];

    // Match debtors with creditors
    while (creditors.length && debtors.length) {
      const creditor = creditors[0];
      const debtor = debtors[0];

      const settledAmount = Math.min(creditor.balance, -debtor.balance);

      transactions.push({
        from: debtor.memberId,
        to: creditor.memberId,
        amount: settledAmount
      });

      creditor.balance -= settledAmount;
      debtor.balance += settledAmount;

      if (creditor.balance === 0) creditors.shift();
      if (debtor.balance === 0) debtors.shift();
    }

    return transactions;
  }, [balances]);

  return (
    <div className="mt-2 overflow-y-auto border divide-y rounded shadow">
      {settlements.length > 0 ? (
      //  settlements.map((transaction, index) => (
      //   <div key={index} className="flex justify-between p-3 hover:bg-gray-50">
      //     <div className="flex items-center">
      //       <p className="text-lg font-semibold text-gray-700">
      //         {memberList.find((member: any) => member._id === transaction.from)?.name} 
      //         <span className="mx-1">has to pay</span> 
      //         <span className="text-green-600">₹ {transaction.amount.toFixed(2)}</span> 
      //         <span className="mx-1">to</span> 
      //         {memberList.find((member: any) => member._id === transaction.to)?.name}
      //       </p>
      //     </div>
      //   </div>

      settlements.map((transaction, index) => (
        <div key={index} className="flex justify-between p-3 hover:bg-gray-50">
    <div className="flex items-center">
      <p className="text-lg font-semibold">
        <span className="text-red-600">
          {memberList.find((member: any) => member._id === transaction.from)?.name}
        </span> 
        {/* <ChevronDoubleRightIcon className="w-6 mx-2 stroke-1 stroke-slate-600" /> */}
        <span className="mx-1">has to pay</span> 
        <span className="text-green-600">
          ₹ {transaction.amount.toFixed(2)}
        </span> 
        <span className="mx-1">to</span> 
        <span className="text-green-600">
          {memberList.find((member: any) => member._id === transaction.to)?.name}
        </span>
      </p>
    </div>
  </div>
        ))
      ) : (
        <div className="p-2 text-center border-2 border-dashed rounded">
          <div className="flex justify-center">
            <CurrencyRupeeIcon className="w-10 stroke-1 stroke-slate-600" />
          </div>
          <h3 className="mt-2 font-medium text-gray-900">No Payments Due</h3>
          <p className="mt-1 text-gray-500">All expenses are settled.</p>
        </div>
      )}
    </div>
  );
};

export default PaymentDue;
