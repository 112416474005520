import { useEffect, useState } from "react";
import { pingServer } from "services/authService";
import Router from "./Router";
import { Loading } from "components";
import { ToastProvider } from "contexts/ToastContext";

function App() {
  const [isServerLoading, setIsServerLoading] = useState(true);
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 4;
  // console.log("Backend API url "+process.env.REACT_APP_API_URL);
  useEffect(() => {
    const ping = async () => {
      try {
        const result = await pingServer();
        if (result) {
          setIsServerLoading(false);
        } else {
          console.log("Backend API url "+process.env.REACT_APP_API_URL);
          throw new Error("Ping failed");
        }
      } catch (error) {
        console.log("Backend API url "+process.env.REACT_APP_API_URL);
        console.error("Ping error:", error);
        if (retryCount < maxRetries) {
          setTimeout(() => {
            setRetryCount(retryCount + 1);
          }, 2000);
        }
      }
    };
    
    ping();
  }, [retryCount]);

  if (isServerLoading) {
    return <Loading isServerLoading={isServerLoading} retryCount={retryCount} maxRetries={maxRetries} />;
  }

  return (
    <ToastProvider>
      <Router />
    </ToastProvider>
  );
}

export default App;
