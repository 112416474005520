import { Breadcrumb } from "components";
import Joi from "joi";
import Button from "components/Button";
import FormInput from "components/FormInput";
import { useParams } from "react-router-dom";
import { authService, expenseService, groupService } from "services";
import ToastContext from "contexts/ToastContext";
import { useContext, useState, useEffect } from "react";

interface Member {
  _id: string;
  name: string;
}

interface GroupDetails {
  members: Member[];
}

const AddExpense = ({ group }: any) => {
  const { groupId } = useParams();
  const currentUser: any = authService.getCurrentUser();
  const [splitEqually, setSplitEqually] = useState(true);
  const [numberOfMembers, setNumberOfMembers] = useState(0);
  const [groupDetails, setGroupDetails] = useState<GroupDetails | null>(null);
  const [splitAmounts, setSplitAmounts] = useState<{ [key: string]: string }>({});
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);

  const { showToast } = useContext(ToastContext);
  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const groupDetails = await groupService.getGroupById(groupId);
        if (groupDetails) {
          setGroupDetails(groupDetails);
          setNumberOfMembers(groupDetails.members.length);
          const initialSplits: { [key: string]: string } = {};
          groupDetails.members.forEach((member: Member) => {
            initialSplits[member._id] = "0";
          });
          setSplitAmounts(initialSplits);
        }
      } catch (error) {
        console.error("Error fetching members:", error);
      }
    };
    fetchMembers();
  }, [groupId]);

  const [data, setData] = useState({
    description: "",
    amount: "",
    groupId: groupId,
    paidBy: currentUser.id,
  });
  const [errors, setErrors] = useState({
    description: "",
    amount: "",
  });

  const schema: any = {
    description: Joi.string().required().label("Description"),
    amount: Joi.string().required().label("Amount"),
    groupId: Joi.string().required().label("Group Id"),
    paidBy: Joi.string().required().label("Paid By"),
  };

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.object(schema).validate(data, options);
    if (!error) return null;
    const errors: any = {};
    for (const item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const validateProperty = ({ name, value }: any) => {
    const obj = { [name]: value };
    const Joischema = { [name]: schema[name] };
    const { error } = Joi.object(Joischema).validate(obj);
    return error ? error.details[0].message : null;
  };

  const handleChange = ({ currentTarget: input }: any) => {
    const { name, value } = input;
    setErrors({
      ...errors,
      [name]: "",
    });
    const errorMessage = validateProperty(input);
    if (errorMessage) {
      setErrors({
        ...errors,
        [name]: errorMessage,
      });
    } else {
      setErrors({
        ...errors,
        [name]: "",
      });
    }

    setData({ ...data, [name]: value });

    if (name === "amount") {
      const amount = parseFloat(value);
      if (splitEqually) {
        const split = isNaN(amount) ? 0 : amount / selectedMembers.length;
        const newSplitAmounts: { [key: string]: string } = {};
        selectedMembers.forEach((memberId) => {
          newSplitAmounts[memberId] = split.toFixed(2);
        });
        setSplitAmounts(newSplitAmounts);
      } else {
        const split = isNaN(amount) ? 0 : amount / selectedMembers.length;
        const newSplitAmounts: { [key: string]: string } = {};
        selectedMembers.forEach((memberId) => {
          newSplitAmounts[memberId] = split.toFixed(2);
        });

        setSplitAmounts(newSplitAmounts);
      }
    } else if (name === "splitEqually" && value === "false") {
      const amount = parseFloat(data.amount);
      const totalEnteredAmount = Object.values(splitAmounts)
        .filter(val => val !== "")
        .reduce((acc, val) => acc + parseFloat(val), 0);
      const remainingAmount = amount - totalEnteredAmount;
      const membersWithoutAmount = Object.values(splitAmounts).filter(val => val === "").length;
      const splitAmountForEachMember = isNaN(remainingAmount) ? 0 : remainingAmount / membersWithoutAmount;

      const newSplitAmounts: { [key: string]: string } = {};
      selectedMembers.forEach((memberId) => {
        if (splitAmounts[memberId] === "") {
          newSplitAmounts[memberId] = splitAmountForEachMember.toFixed(2);
        } else {
          newSplitAmounts[memberId] = splitAmounts[memberId];
        }
      });

      setSplitAmounts(newSplitAmounts);
    }
  };

  const handleSplitChange = (memberId: string, value: string) => {
    setSplitAmounts({ ...splitAmounts, [memberId]: value });
  };

  const handleAddExpense = async (e: any) => {
    e.preventDefault();
    const errors = validate();
    setErrors(errors);
    if (errors) return;
    doSubmit();
  };

  const doSubmit = async () => {
    try {
      const splits = Object.entries(splitAmounts).map(([memberId, amount]) => ({
        memberId,
        amount: parseFloat(amount),
      }));

      const result = await expenseService.addExpense({ ...data, membersBalance: splits });
      if (result) {
        showToast("Expense added successfully", "success");
        window.location.href = `/group/detail/${groupId}`;
      }
    } catch (error: any) {
      if (error.response) {
        setErrors({ ...errors });
      }
    }
  };

  const calculateEqualSplit = () => {
    const amount = parseFloat(data.amount);
    let split = isNaN(amount) ? 0 : amount / selectedMembers.length;
    return split.toFixed(2);
  };

  const validateUnequalSplits = () => {
    const total = Object.values(splitAmounts).reduce(
      (acc, val) => acc + parseFloat(val || "0"),
      0
    );
    const tolerance = 0.01; // Adjust tolerance as needed
    return Math.abs(total - parseFloat(data.amount)) < tolerance;
  };

  const toggleMemberSelection = (memberId: string) => {
    setSelectedMembers(prevSelected => 
      prevSelected.includes(memberId)
        ? prevSelected.filter(id => id !== memberId)
        : [...prevSelected, memberId]
    );
  };

  return (
    <div className="flex flex-col flex-1 h-full px-4 mt-4 sm:px-6 lg:mx-auto lg:px-8 xl:max-w-6xl">
      <Breadcrumb
        paths={[
          { name: "Groups", to: "/groups" },
          { name: "Group Detail", to: `/group/detail/${groupId}` },
          { name: "Add Expense", to: `/group/${groupId}/addexpense` },
        ]}
      />
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
            Add Expense
          </h2>
        </div>
      </div>

      <div className="max-w-lg mt-6">
        <FormInput
          label="Description"
          name="description"
          placeholder="Enter description"
          value={data.description}
          onChange={handleChange}
          error={errors ? errors.description : ""}
        />
        <FormInput
          label="Amount"
          name="amount"
          placeholder="0.00"
          type="number"
          showLeadingIcon
          value={data.amount}
          onChange={handleChange}
          error={errors ? errors.amount : ""}
        />

<div className="mt-4 mb-2 text-lg font-medium">
  Select participant members :
  </div>
  <div className="flex items-center mt-4">

  <button
    className="px-3 py-1 mr-2 text-sm font-medium text-gray-400 border border-gray-400 rounded-full bg-white-200 max-w-fit"
    onClick={() => setSelectedMembers(groupDetails?.members.map(member => member._id) || [])}
  >
    Select All
  </button>
  <button
    className="px-3 py-1 text-sm font-medium text-gray-400 border border-gray-400 rounded-full bg-white-200 max-w-fit"
    onClick={() => setSelectedMembers([])}
  >
    Clear All
  </button>
</div>

<div className="flex flex-wrap m-3">
  {/* {groupDetails?.members.map((member) => (
    <div key={member._id} className="flex items-center mb-3">
      <div
        className={`px-3 py-1 mr-2 mt-2 font-medium border rounded text-m transition-all ${
          selectedMembers.includes(member._id)
            ? "bg-cyan-600 text-white border-transparent"
            : "bg-white text-cyan-600 border-cyan-600 hover:border-white hover:shadow-inner"
        }`}
        onClick={() => toggleMemberSelection(member._id)}
      >
       {member.name.split(' ')[0]}
      </div>
    </div>
  ))} */}


  
  {groupDetails?.members.map((member) => (
  <div key={member._id} className="flex items-center mb-3">
    <div
      className={`px-3 py-1 mr-3 font-medium border rounded text-medium transition-all ${
        selectedMembers.includes(member._id)
          ? "bg-cyan-600 text-white border-transparent"
          : "bg-white text-cyan-600 border-cyan-600 hover:border-white hover:shadow-inner"
      }`}
      onClick={() => toggleMemberSelection(member._id)}
    >
      {groupDetails?.members.filter(m => m.name.split(' ')[0] === member.name.split(' ')[0]).length > 1 ? member.name : member.name.split(' ')[0]}
    </div>
  </div>
))}
</div>



<h3 className="mt-4 mb-2 text-lg font-medium">Select Split method :</h3>


<button
  id="equally"
  className={`px-3 py-1 ml-3 mt-3 mb-3 font-semibold rounded-md max-w-fit shadow-sm transition-colors ${
    splitEqually
      ? "text-white bg-cyan-600 hover:shadow-inner"
      : "text-cyan-600 bg-white border border-cyan-600 hover:shadow-inner"
  }`}
  onClick={() => setSplitEqually(true)}
>
  Split Equally
</button>

<button
  id="unequally"
  className={`px-3 py-1 ml-3 mt-3 mb-3 text-m font-semibold rounded-md max-w-fit shadow-sm transition-colors ${
    !splitEqually
      ? "text-white bg-cyan-600 hover:shadow-inner"
      : "text-cyan-600 bg-white border border-cyan-600 hover:shadow-inner"
  }`}
  onClick={() => setSplitEqually(false)}
>
  Split Unequally
</button>




        <div className="mt-3 ml-3">
  {splitEqually ? (
    <>
      {selectedMembers.map((memberId, index) => {
        const member = groupDetails?.members.find(m => m._id === memberId);
        const splitAmount = calculateEqualSplit();
        const formattedAmount = Number.isInteger(parseFloat(splitAmount))
          ? parseInt(splitAmount, 10).toString()
          : splitAmount.toString();

        return member ? (
          <div key={member._id} className="flex items-center justify-between p-3 mb-3 bg-white border rounded-md shadow-sm">
            <div className="flex items-center">
              <span className="flex items-center justify-center flex-shrink-0 w-8 h-8 mr-3 text-lg font-semibold text-white rounded-full bg-cyan-600">
                {index + 1}
              </span>
              <span className="text-lg font-medium text-gray-500">{member.name}</span>
            </div>
            <span className="text-lg font-medium text-red-400">{formattedAmount}</span>
          </div>
        ) : null;
      })}
    </>
  ) : (
    <div className="mt-3 ml-3">
      <table className="w-full">
        <tbody>
          {selectedMembers.map((memberId, index) => {
            const member = groupDetails?.members.find(m => m._id === memberId);
            const splitAmount = calculateEqualSplit();
            const formattedAmount = Number.isInteger(parseFloat(splitAmount))
              ? parseInt(splitAmount, 10).toString()
              : splitAmount.toString();

            return member ? (
              <tr key={member._id} className="flex items-center justify-between p-3 mb-3 bg-white border rounded-md shadow-sm">
                <td className="flex items-center">
                  <span className="flex items-center justify-center flex-shrink-0 w-8 h-8 mr-3 text-lg font-semibold text-white rounded-full bg-cyan-600">
                    {index + 1}
                  </span>
                  <span className="text-lg font-medium text-gray-500">{member.name} :</span>
                </td>
                <td>
                  <input
                    type="number"
                    className="px-2 py-1 mt-1 text-lg text-red-400 border rounded border-zinc-400 focus:border-cyan-500 focus:outline-none focus:ring-2 focus:ring-cyan-200 focus:ring-offset-0"
                    value={splitAmounts[member._id]}
                    placeholder={formattedAmount}
                    onChange={(e) =>
                      handleSplitChange(member._id, e.target.value)
                    }
                  />
                </td>
              </tr>
            ) : null;
          })}
        </tbody>
      </table>
    </div>
  )}
</div>














        <Button
          margin="mt-6"
          width="w-full"
          disabled={validate() || (!splitEqually && !validateUnequalSplits())}
          onClick={handleAddExpense}
        >
          Add Expense
        </Button>
      </div>
    </div>
  );
};

export default AddExpense;
